<template>
    <div>
        <div class="text-center mt-section size-24 fw-ekstra-bold">
            Welcome to Dashboard
        </div>
        <div class="mt-2 text-center">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        </div>
    </div>
</template>

<script>
    export default {
        props: '',
        name: 'Dashboard',
        data() {
            return{
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style>
</style>